<template>
  <v-layout class="page" fill-height>
    <div class="conBox step3">
      <ul class="sTab">
        <li v-for="(item, i) in tabList" :key="i">
          <a :class="item.class" @click.prevent="changeTab(i)" v-ripple>{{ item.name }}</a>
        </li>
      </ul>
      <ul class="detTab">
        <li class="cobb" :class="{ 'on': curIndex == 0 }" v-show="curIndex == 0" :key="'cobb'">
          <ul class="cobbDiv">
            <li>
              <div class="contTitW">
                <p class="contTit">{{ READING_SPINE_NAME }} 분석 결과</p>
              </div>
              <spine-rslt-compo v-if="xrayMap.spine" :xrayMap="xrayMap.spine" />
            </li>
            <li class="history">
              <div class="contTitW">
                <p class="contTit">히스토리 비교</p>
                <div class="btnW" style="width:auto;">
                  <button class="btn solid small" v-ripple @click="historySpinePop = true;"><img src="../../../assets/images/data_check.svg" alt="">이력 선택</button>
                  <button class="btn default small" @click="graphSpinePop = true; graphSpineKey += 1;" v-ripple><img src="../../../assets/images/query_stats.svg" alt="">그래프 조회</button>
                </div>
              </div>
              <ul class="historyList" v-if="historySpineImageList && historySpineImageList.length > 0">
                <li class="xrayImg" v-for="(item, i) in historySpineImageList" :key="i">
                  <spine-rslt-compo :xrayMap="item" />
                </li>
              </ul>
              <div class="emptyBox" v-else>[이력 선택] 버튼을 클릭하여<br />비교할 이력을 선택하세요.</div>
            </li>
          </ul>
        </li>
        <li class="bone" :class="{ 'on': curIndex === 1 }" v-show="curIndex === 1" :key="'bone'">
          <ul class="boneDiv">
            <li class="topDiv">
              <div class="div">
                <div class="styleTitW">
                  <p class="styleTit">{{ READING_HANDS_NAME }} 분석 결과</p>
                </div>
                <hands-rslt-compo v-if="xrayMap.hands" :title="READING_HANDS_NAME + ' 분석 결과'" :xrayMap="xrayMap.hands" />
              </div>
              <div class="div">
                <div class="styleTitW">
                  <p class="styleTit">수부 Atlas 자료 비교</p>
                </div>
                <hands-at-rslt-compo :xrayMap="xrayMap.hands" :key="atResultKey" />
              </div>
            </li>
            <li class="botDiv">
              <div class="titW">
                <div class="styleTitW">
                  <p class="styleTit">히스토리 비교</p>
                  <div class="hisBtnW">
                    <button class="btn solid small" @click="historyHandsPop = true;" v-ripple><img src="../../../assets/images/data_check.svg" alt=""> 이력 선택</button>
                    <button class="btn default small" @click="graphHandsPop = true;  graphHandsKey += 1;" v-ripple><img src="../../../assets/images/query_stats.svg" alt="">그래프 조회</button>
                  </div>
                </div>
              </div>
              <ul class="imgDiv" v-if="historyHandsImageList && historyHandsImageList.length > 0">
                <hands-rslt-compo v-for="(item, i) in historyHandsImageList" :key="i" :xrayMap="item" />
              </ul>
              <ul class="imgDiv" v-else>
                <li class="emptyBox">비교 히스토리가 없습니다. </li>
              </ul>
            </li>
          </ul>
        </li>
        <li class="result" :class="{ 'on': curIndex === 2 }" v-show="curIndex === 2" :key="'result'" v-if="type === 'both'">
          <tot-rslt-compo :xrayMap="xrayMap" :xrayNo="xrayNo"/>
        </li>
      </ul>
    </div>
    <transition name="fade">
      <history-popup v-if="historySpinePop" :xrayType="XRAY_TYPE_SPINE" :historyNoList="historySpineNoList" :xrayNo="xrayMap?.spine?.xrayNo" @selectHistory="selectHistory" @closePopup="historySpinePop = false;" />
      <history-popup v-if="historyHandsPop" :xrayType="XRAY_TYPE_HANDS" :historyNoList="historyHandsNoList" :xrayNo="xrayMap?.hands?.xrayNo" @selectHistory="selectHistory" @closePopup="historyHandsPop=false;" />
      <graph-popup v-if="graphSpinePop" :xrayName="READING_SPINE_NAME" :xrayType="XRAY_TYPE_SPINE" :xrayNo="xrayMap?.spine?.xrayNo" @closePopup="graphSpinePop=false;" :key="graphSpineKey"/>
      <graph-popup v-if="graphHandsPop" :xrayName="READING_HANDS_NAME" :xrayType="XRAY_TYPE_HANDS" :xrayNo="xrayMap?.hands?.xrayNo" @closePopup="graphHandsPop=false;" :key="graphHandsKey" />
    </transition>
  </v-layout>
</template>

<script>
import API_RESULT from "../../../API/reading/result";
import API_HISTORY_IMAGE from "../../../API/reading/historyImage";
import historyPopup from "../../../popup/historyPopup";
import graphPopup from "../../../popup/graphPopup";
import EventBus from "../../../plugins/EventBus";
import Constants from '../../../plugins/Const'


export default {
    components: {
        historyPopup,
        graphPopup,
        handsAtRsltCompo: () => import('@/components/handsAtRsltCompo.vue'),
    },
    inject: ["setRightSideHidden"],
    props: {},
    data() {
      return {
        tabHeader: {
          spine: { name: Constants.READING_SPINE_NAME, class: '' },
          hands: { name: Constants.READING_HANDS_NAME, class: '' },
          tot: { name: "통합 분석 결과", class: "" },
        },
        tabList: [],
        curIndex: 0,
        xrayMap: {
          spine: {},
          hands: {}
        },
        historySpinePop: false,
        historyHandsPop: false,
        historySpineNoList: [],
        historySpineImageList: [],
        historyHandsNoList: [],
        historyHandsImageList: [],
        graphSpinePop: false,
        graphHandsPop: false,
        atResultKey: 0,
        graphHandsKey: 0,
        graphSpineKey: 0,
        xrayNo: this.$route.params.no,
        type: this.$route.params.type,
        pineDx: process.env.VUE_APP_PINE_DX
      };
    },
    watch: {},
    created() { },
    mounted() {
      this.setRightSideHidden(true)
      this.getResult(this.$route.params.no);
    },
  destroyed() { 
      this.setRightSideHidden(false);
      this.initLocalStorage();
    },
    methods: {
    async getResult(no) {
      let res = await API_RESULT.request(no);
      if (res.isSuccess) {
        const resMap = {
          spine: res.spine,
          hands: res.hands,
        };
        this.xrayMap = resMap;
        if (this.xrayMap?.spine) this.roundAngles(this.xrayMap?.spine);
        EventBus.emit("reading_end", this.xrayMap);
        this.setTab();
        this.atResultKey += 1;
      } else {
        this.showPopup(res.errorMsg, res.status);
      }
    },
      setTab() {
      if (this.$route.params.type) {
        let type = this.$route.params.type;
        switch (type) {
          case 'spine':
            this.curIndex = 0
            break;
          case 'hands':
            this.curIndex = 1
            break;
        }
      }
      if (this.xrayMap?.spine) this.tabList.push(this.tabHeader.spine);
      if (this.xrayMap?.hands) this.tabList.push(this.tabHeader.hands);
      if (this.xrayMap?.spine && this.xrayMap?.hands) this.tabList.push(this.tabHeader.tot);
      if (this.tabList && this.tabList.length > 0) this.tabList[0].class = "on";
    },
      changeTab(index) {
      this.tabList.forEach((item) => {
        item.class = "";
      });
      this.tabList[index].class = "on";
      this.curIndex = index;
    },
    selectHistory(xrayType, event) {
      if (!event || (event && event.length == 0)) {
        this.historySpineNoList = [];
        this.historyHandsNoList = [];
        this.historySpineImageList = [];
        this.historyHandsImageList = [];
      } else {
        if (xrayType == this.XRAY_TYPE_SPINE) {
          this.historySpineNoList = event;
          this.getSpineHistoryImage(
            this.XRAY_TYPE_SPINE,
            this.historySpineNoList
          );
        } else if (xrayType == this.XRAY_TYPE_HANDS) {
          this.historyHandsNoList = event;
          this.getSpineHistoryImage(
            this.XRAY_TYPE_HANDS,
            this.historyHandsNoList
          );
        }
      }
      this.historySpinePop = false;
      this.historyHandsPop = false;
    },
    async getSpineHistoryImage(xrayType, historyNoList) {
      let res = await API_HISTORY_IMAGE.request(xrayType, historyNoList);
      if (res.isSuccess) {
        if(xrayType == this.XRAY_TYPE_SPINE){
          this.historySpineImageList = res.list;
          for (let image of this.historySpineImageList) {
            this.roundAngles(image);
          }
        } else if (xrayType == this.XRAY_TYPE_HANDS) {
          this.historyHandsImageList = res.list;
        }
      } else {
        this.showPopup(res.errorMsg, res.status);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../sass/app.scss";
.v-application {
  .blue, .red {background-color: transparent !important; border-color: transparent !important;}
  .totalReportView {position: absolute; right: 40px; top: 40px;}
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

button{
  transition: box-shadow 0.5s, opacity 0.5s;
}
button:hover{
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  opacity: 0.7;
}

.cobbDiv .contTitW{
  position:relative;
  height: auto;
  min-height: 32px;
  .contTit{
    position: relative;
    padding-left: 16px;
    color: var(--color-s80);
    font-size: 20px;
    font-weight: 600;
  }
}

.bone .boneDiv .botDiv .titW{margin-bottom: 0;}
.bone .boneDiv .styleTitW{width: 100%; position: relative; height: auto; min-height: 32px; margin-bottom: 16px; display: flex; align-items: center; justify-content: space-between;}
.bone .boneDiv  .styleTit{margin-bottom: 0;}
</style>
